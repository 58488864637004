import { Container, Fade, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactElement } from "react";
import { HttpClient } from "../api/HttpClient";
import { RecentRoomsResponse } from "../api/Requests";
import { AuthState, UserContext } from "../contexts/UserContext";
import { Action, Category } from "../analytics/Tracking";
import { useJoinRoom } from "../hooks/useRoom";
import { createStyles, makeStyles } from "@mui/styles";
import { useTracking } from "../analytics/useTracking";

const useStyles = makeStyles(() =>
  createStyles({
    footer: {},
    itemUrl: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  })
);

export default function RecentRooms(): ReactElement {
  const { track } = useTracking();
  const { authState, authToken } = useContext(UserContext);
  const [, setIsLoading] = useState(false);
  const [response, setResponse] = useState<RecentRoomsResponse>();
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSizeMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [joinRoom] = useJoinRoom();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const httpClient = new HttpClient(authToken);
        const r = (await httpClient.get("/rooms/recent")) as RecentRoomsResponse;

        setResponse(r);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
    if (authState == AuthState.SignedIn) {
      fetchData();
    }
  }, [authState]);

  const roomLink = (roomUrlId: string) => {
     const preFix = isScreenSizeMobile ? window.location.origin + "/" : "";
    return  preFix + roomUrlId;
  }

  return (
    <Fade in={true}>
    <Container maxWidth="md">
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Recent Rooms</TableCell>
            <TableCell align="right">Players</TableCell>
            <TableCell align="right">Last Visited</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response?.recentRoomVisits.map((row) => (
            <TableRow key={row.roomUrlId}>
              <TableCell component="th" scope="row">
                <Link color="inherit" className={classes.itemUrl} href={window.location.origin + "/" + row.roomUrlId} variant="body1" onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  track.event(Category.User, Action.RecentRoomVisited);
                  joinRoom(row.roomUrlId, row.player.id, row.player.name, row.player.isModerator)
                  }}>
                  {roomLink(row.roomUrlId)}
              </Link>
              </TableCell>
              <TableCell align="right">{row.playerNames}</TableCell>
              <TableCell align="right">{row.daysSinceLastVisit} {row.daysSinceLastVisit == 1 ? "day" : "days"} ago</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </Fade>
  );
}