import React, { ReactElement, SyntheticEvent, useContext, useState } from 'react';
import Grid from "@mui/material/Grid";
import { createStyles, makeStyles } from "@mui/styles";
import { TextField, Theme, Typography } from "@mui/material";
import { LoadingButton } from "../components/LoadingButton";
import { useAlert } from "../contexts/alert/useAlert";
import { ConflictError, HttpClient, NotFoundError } from "../api/HttpClient";
import { Action, Category } from "../analytics/Tracking";
import { InputValidatorMode, useUserNameValidator } from "../contexts/useInputValidator";
import { usePlayer } from '../contexts/usePlayer';
import { PlayerContext } from '../contexts/PlayerContext';
import { Session, SessionStorage } from '../localstorage/SessionStorage';
import { useTracking } from '../analytics/useTracking';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		link: {
			maxWidth: 300,
			fontStyle: "italic",
			textAlign: "center",
		},
	})
);

export interface RenamePlayerProps {
	roomUrlId: string;
	onCompleted: (newPlayerName: string) => void;
}

export function RenamePlayer(props: RenamePlayerProps): ReactElement {
	const alert = useAlert();
	const sessionStorage = new SessionStorage();
	const { track } = useTracking();
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();
	const {player, updatePlayer }= usePlayer();
	const [newPlayerName, setNewPlayerName] = useState(player?.name);
	const [validateUserName] = useUserNameValidator();
	const [isValid, setIsValid] = useState(true);

	function onCompleted(newPlayerName: string) {
		props.onCompleted(newPlayerName);
	}

	function validateInput(inputUserName: string, mode: InputValidatorMode) {
		const isValid = validateUserName(inputUserName, mode).isValid;
		setIsValid(isValid);
		return isValid;
	}

	async function handleRenamePlayer(event: SyntheticEvent) {
		event.preventDefault();
	
		if (!validateInput(newPlayerName as string, InputValidatorMode.onSubmit)) {
			return;
		}

		setIsLoading(true);
		const httpClient = new HttpClient();

		try {
			(await httpClient.post("/rooms/renamePlayer", { roomUrlId: props.roomUrlId, playerId: player?.id, newPlayerName: newPlayerName }));

			setIsLoading(false);

			track.event(Category.Room, Action.PlayerRenamed);
			if (player && newPlayerName) {
				sessionStorage.UpdatePlayerName(props.roomUrlId, newPlayerName);
				updatePlayer(newPlayerName, player.isModerator);
				onCompleted(newPlayerName);
			}
			
		} catch (e) {
			let errorMessage = "Could not rename player, please try again 🤞";

			if (e instanceof ConflictError) {
				console.error("HandleRenamePlayer - Player name already exists.");
				errorMessage = "Player name already in use, please try another 👍";
			} else if (e instanceof NotFoundError) {
				console.error("HandleRenamePlayer - Room not found.");
				errorMessage = "Player not found in room 🤞";
			}
			setIsLoading(false);
			alert.showMessage(errorMessage, "error");
		} 
	}

	function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		setNewPlayerName(event.target.value);
		validateInput(event.target.value, InputValidatorMode.onTyping);
	}

	return (
		<>
			<form onSubmit={handleRenamePlayer}>
				<Grid container className={classes.container} spacing={3} direction="column" justifyContent="center">
					<Grid item>
						<Typography align="center" variant="h5">Rename Player</Typography>
						<Typography align="center" variant="subtitle1">and change your identity 🤖</Typography>
					</Grid>
					<Grid item>
						<TextField
							autoFocus={true}
							onFocus={(event) => event.target.select()}
							value={newPlayerName}
							error={!isValid}
							helperText={validateUserName(newPlayerName as string, InputValidatorMode.onTyping).errorMessage}
							disabled={isLoading}
							name="newPlayerName"
							id="outlined-basic"
							label="Enter new Player Name"
							variant="outlined"
							color="secondary"
							onChange={handleOnChange}
						/>
{/*							onChange={(event) => handleOnChangeNewName(event)}*/}
					</Grid>
					<Grid item>
						<LoadingButton type="submit" isLoading={isLoading}>Rename</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</>
	);
}