import { FirebaseApp } from "firebase/app";
import React, { useEffect } from "react";
import { ReactElement } from "react";
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { firebaseConfigDev, firebaseConfigProd } from "../analytics/firebaseConfig";
import { useEnvironment } from "../hooks/useEnvironment";

interface FirebaseProviderProps {
    onAppInitialized: (app: FirebaseApp) => void;
    onAnalyticsInitialized: (analytics: Analytics) => void;
}

export function FirebaseProvider(props: React.PropsWithChildren<FirebaseProviderProps>): ReactElement {
  const [isDevelopment] = useEnvironment();
  
  useEffect(() => {
    const initializeFirebase = async () => {
      console.log("initializing firebase provider");
      const firebaseConfig = isDevelopment() ? firebaseConfigDev : firebaseConfigProd;
      const app = initializeApp(firebaseConfig);
      if (app != null) {
        props.onAppInitialized(app);
        //console.log("firebase provider app initialized");
      }
      const analytics = getAnalytics(app);
      if (analytics != null) {
        props.onAnalyticsInitialized(analytics);
        //console.log("firebase provider analytics initialized");
      }

    };
    initializeFirebase();
  }, []);

  return <>{props.children}</>;
}
