import { Close } from "@mui/icons-material";
import { Grid, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            background: theme.palette.background.paper,
            marginBottom: theme.spacing(3),
            borderRadius: 5,
            overflow: "hidden",
        },
        header: {
            display: "flex",
            alignItems: "center",
            background: theme.palette.primary.main,
            padding: theme.spacing(0.3),
        },
        itemContent: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        footer: {},
        h6: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
    })
);

export interface WindowGridContainerProps {
    title: string;
    onClosed?: () => void;
}

export function WindowGridContainer(props: React.PropsWithChildren<WindowGridContainerProps>): ReactElement {
    const classes = useStyles();

    const handleClose = () => {
        if (props.onClosed) {
          props.onClosed();
        }
    };

    return (
        <Grid className={classes.container} container spacing={0} direction="row">
            <Grid className={classes.header} item xs></Grid>
            <Grid className={classes.header} item>
                <Typography className={classes.h6} variant="h6">
                    {props.title}
                </Typography>
            </Grid>
            <Grid className={classes.header} item xs container justifyContent="flex-end">
                {props.onClosed &&
                <IconButton size="small" onClick={handleClose}>
                    <Close />
                </IconButton>
                }
            </Grid>

            <Grid className={classes.itemContent} container spacing={0} alignItems="center" justifyContent="space-around">
                {props.children}
            </Grid>

            <Grid item xs={12} className={classes.footer}></Grid>
        </Grid>
    );
}
