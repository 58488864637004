import React, { useState, useContext, SyntheticEvent, ReactElement } from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { PlayerContext } from '../contexts/PlayerContext';
import { Theme, Typography } from '@mui/material';
import { HttpClient } from '../api/HttpClient';
import { useAlert } from '../contexts/alert/useAlert';
import { LoadingButton } from '../components/LoadingButton';
import { AppSettingsStorage } from '../localstorage/AppSettingsStorage';
import { Category, Action } from '../analytics/Tracking';
import { InputValidatorMode, useUserNameValidator } from '../contexts/useInputValidator';
import { CreateRoomRequest, RoomResponse } from '../api/Requests';
import { useJoinRoom } from '../hooks/useRoom';
import { useTracking } from '../analytics/useTracking';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        },
    }),
);

export default function CreateRoom(): ReactElement {
    const [validateUserName] = useUserNameValidator();
    const [userName, setUserName] = useState('');
    useContext(PlayerContext);
    const classes = useStyles();
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const { track } = useTracking();
    const [joinRoom] = useJoinRoom();

    function validateInput(inputUserName: string, mode: InputValidatorMode) {
        const isValid = validateUserName(inputUserName, mode).isValid;
        setIsValid(isValid);
        return isValid;
    } 

    function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setUserName(event.target.value);
        validateInput(event.target.value, InputValidatorMode.onTyping);
    }

    async function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        if (!validateInput(userName, InputValidatorMode.onSubmit)) {
            return;
        }

        setIsLoading(true);
        const httpClient = new HttpClient();
        try {
            const request = new CreateRoomRequest(userName);

            const appSettings = new AppSettingsStorage();

            const defaultCardDeckType = appSettings.GetDefaultCardDeckType();
            if (defaultCardDeckType) {
                //console.log('creating room with card deck types: ' + defaultCardDeckType);
                request.cardDeckType = defaultCardDeckType;
            }

            const defaultPlayingCards = appSettings.GetDefaultPlayingCards();
            if (defaultPlayingCards) {
                //console.log('creating room with custom cards: ' + defaultPlayingCards);
                request.playingCards = defaultPlayingCards;
            }

            const response = await httpClient.post('/rooms', request) as RoomResponse;

            const player = response.players.find(p => p.name === userName);
            if (player) {
                track.event(Category.Room, Action.RoomCreated);
                joinRoom(response.urlId, player.id, userName, true);

            } else {
                setIsLoading(false);
                alert.showMessage("an unexpected error occured, please try again 🤞", "error");
            }

        } catch (e) {
            setIsLoading(false);
            alert.showMessage("Not able to create room, please try again 🤞", "error");
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography align="center" variant="h5">Create a planning poker room</Typography>
                        <Typography align="center" variant="subtitle1">and start estimating with your team right away</Typography>
                    </Grid>
                    <Grid item><TextField autoComplete="nickname" error={!isValid} helperText={validateUserName(userName,InputValidatorMode.onTyping).errorMessage} disabled={isLoading} onChange={event => handleOnChange(event)} id="outlined-basic" label="Please enter your name" variant="outlined" color="secondary" /></Grid>
                    <Grid item><LoadingButton type="submit" isLoading={isLoading}>Create Room</LoadingButton></Grid>
                </Grid>
            </form>
        </div>
    );
}