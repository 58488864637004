import React, { ReactElement, useContext } from "react";
import { Divider, Grid, IconButton, Menu, MenuItem, Theme, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { ContextPlayer } from "../contexts/PlayerContext";
import { useSignOut } from "../auth/useAuthentication";
import { AuthState, UserContext } from "../contexts/UserContext";
import { useHistory } from "react-router-dom";

interface UserMenuProps {
    player?: ContextPlayer;
    onSettingsClicked: () => void;
    onSignInClicked: () => void;
    onSignUpClicked: () => void;
    onSignOutClicked: () => void;
    onManageSubscriptionClicked: () => void;
    onRecentRoomsClicked: () => void;
}

export function UserMenu(props: UserMenuProps): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { authState, user } = useContext(UserContext);
    const [signOut] = useSignOut();
    const history = useHistory();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSettingsClicked = () => {
        handleCloseMenu();
        props.onSettingsClicked();
    };

    const handleSignInClicked = () => {
        handleCloseMenu();
        props.onSignInClicked();
    };

    const handleSignUpClicked = () => {
        handleCloseMenu();
        props.onSignUpClicked();
    };

    const handleSignOutClicked = () => {
        handleCloseMenu();
        //TODO pri2: nice if signout could be handeled outside the menu
        signOut();
        props.onSignOutClicked();
    };

    const handleManageSubscriptionClicked = () => {
        handleCloseMenu();
        props.onManageSubscriptionClicked();
        //TODO pri2: move functionality outside the menu, but it requires App to be refactored becasue history will be undefined as it is right now
        history.push("/subscription");
    };

    const handleRecentRoomsClicked = () => {
        handleCloseMenu();
        props.onRecentRoomsClicked();
        //TODO pri2: move functionality outside the menu, but it requires App to be refactored becasue history will be undefined as it is right now
        history.push("/");
    };

    const avatarSrc = (playerName: string) => {
        return "https://api.dicebear.com/7.x/bottts/svg?seed=" + playerName;
    };

    return (
        <div>
            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography sx={{ color: "primary.contrastText" }} variant="body1">
                        {" "}
                        {props.player?.name ? props.player.name : user?.email}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                        {authState == AuthState.SignedIn && props.player ? (
                            <img src={avatarSrc(props.player.name)} alt="" onClick={handleMenu} width={24} height={24} />
                        ) : (
                            <AccountCircle />
                        )}
                    </IconButton>
                </Grid>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={handleCloseMenu}
                >
                    {props.player?.isModerator && (
                        <MenuItem onClick={handleSettingsClicked}>
                            <Typography color="textPrimary">Configure Card Deck</Typography>
                        </MenuItem>
                    )}
                    {user?.plan.subscription && (
                        <MenuItem onClick={handleManageSubscriptionClicked}>
                            <Typography color="textPrimary">Manage Subscription</Typography>
                        </MenuItem>
                    )}

                    {authState == AuthState.SignedIn && (
                        <>
                        <MenuItem onClick={handleRecentRoomsClicked}>
                            <Typography color="textPrimary">Recent Rooms</Typography>
                        </MenuItem>
                        </>
                    )}

                    {props.player && <Divider />}

                    {authState == AuthState.SignedOut ? (
                        <>
                            <MenuItem onClick={handleSignInClicked}>
                                <Typography color="textPrimary">Sign In</Typography>
                            </MenuItem>

                            <MenuItem onClick={handleSignUpClicked}>
                                <Typography color="textPrimary">Sign Up</Typography>
                            </MenuItem>
                        </>
                    ) : (
                        
                        <MenuItem onClick={handleSignOutClicked}>
                            <Typography color="textPrimary">Sign Out</Typography>
                        </MenuItem>
                    )}
                </Menu>
            </Grid>
        </div>
    );
}
