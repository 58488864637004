import React, { useState, useContext, useEffect, useRef, ReactElement } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CardDeck, { StoryPointCard } from "./CardDeck";
import GameStateMessage from "./GameStateMessage";
import ModerationPanel from "./ModerationPanel";
import EstimationResult, { PlayerEstimate } from "./EstimationResult";
import { HttpClient } from "../api/HttpClient";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useAlert } from "../contexts/alert/useAlert";
import { SessionStorage } from "../localstorage/SessionStorage";
import { CardSelectedMessage, GameStateChangedMessage, Message, PlayingCardsUpdatedMessage } from "../api/SignalR";
import { GameStorage } from "../localstorage/GameStorage";
import { Category, Action, Timing } from "../analytics/Tracking";
import { CircularProgress, Theme } from "@mui/material";
import { PlayerState, PlayerViewModel } from "../viewModels/PlayerViewModel";
import { StoryPoint } from "../types/StoryPoint";
import PlayerList from "./PlayerList";
import { useHistory } from "react-router-dom";
import SignUpTeaser from "../auth/SignUpTeaser";
import { CardDeckType, EnrollModeratorPowersRequest, RoomResponse } from "../api/Requests";
import { AuthState, UserContext } from "../contexts/UserContext";
import GameInsights from "./GameInsights";
import { usePlayer } from "../contexts/usePlayer";
import { SignUpDialog } from "../auth/SignUpDialog";
import { ContextPlayer } from "../contexts/PlayerContext";
import { useTracking } from "../analytics/useTracking";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
        },
        outerContainer: {
            padding: theme.spacing(4),
            maxWidth: (props: { playingCardsCount: number }) => (props.playingCardsCount < 7 ? 900 : props.playingCardsCount < 9 ? 1100 : 1400),
        },
        loaderContainer: {
            minHeight: 200,
        }
    })
);

export enum GameState {
    NotStarted = "not_started",
    WaitingForPLayers = "waiting_for_players",
    Playing = "playing",
    Result = "result",
    Loading = "loading",
    Suspended = "suspended",
}

export interface RoomProps {
    roomUrlId: string;
}

let _roundCount = 0;

export default function Room(props: RoomProps): ReactElement {
    const alert = useAlert();
    const gameStorage = new GameStorage();
    const sessionStorage = new SessionStorage();
    const {track} = useTracking();
    const history = useHistory();

    const { player: contextPlayer, setPlayer } = usePlayer();
    const { user, authState, authToken } = useContext(UserContext);
    const [gameState, setGameState] = useState<GameState>(GameState.Loading);
    const [cardDeckType, setCardDeckType] = useState<CardDeckType>();
    const connection = useRef<HubConnection>(new HubConnectionBuilder().withAutomaticReconnect().configureLogging(LogLevel.Error).withUrl("/roomhub").build());
    const [players, setPlayers] = useState<PlayerViewModel[]>([]);
    const playersRef = useRef(players);
    const [playingCards, setPlayingCards] = useState<StoryPointCard[]>([]);
    const playingCardsRef = useRef(playingCards);
    const classes = useStyles({ playingCardsCount: gameStorage.GetNumberOfPlayingCards() });
    const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);

    async function loadDataAndUpdateState(playerId: string): Promise<PlayerViewModel | undefined> {
        try {
            //TODO pri1: is it possible to avoid fetching the board once again after create/join? how to pass probs through the router
            const httpClient = new HttpClient();
            const response = (await httpClient.get("/rooms/" + props.roomUrlId)) as RoomResponse;

            playersRef.current = response.players.map(
                (p) =>
                    new PlayerViewModel(
                        p.id,
                        p.userId,
                        p.name,
                        p.isModerator,
                        p.isObserver,
                        p.id === playerId ? PlayerState.Active : p.connections.length > 0 ? PlayerState.Active : PlayerState.Inactive,
                        p.storyPoint as StoryPoint
                    )
            );

            //set local user state after browser refresh
            const player = playersRef.current.find((p) => p.id === playerId);
            if (player) {
                setPlayer(new ContextPlayer(player.id, player.name, player.isModerator));

                setPlayers([...playersRef.current]);
                const orderedCards = response.playingCards.sort((c) => c.order);
                playingCardsRef.current = orderedCards.map((p) => ({ storyPoint: p.value as StoryPoint, selected: player?.storyPoint === p.value }));
                setPlayingCards([...playingCardsRef.current]);
                gameStorage.SaveNumberOfPlayingCards(playingCardsRef.current.length);
                setGameState(response.state as GameState);
                setCardDeckType(response.cardDeckType);
                return player;
            } else {
                alert.showMessage("You are no longer a part of the room, please refresh your browser to rejoin 🤞", "error");
                sessionStorage.DeleteSession(props.roomUrlId);
            }
        } catch (e) {
            alert.showMessage("Not able to load room, please try again 🤞", "error");
        }
        return undefined;
    }

    //TODO pri3: connection handling should be extracted to something independant from UI renders
    useEffect(() => {
        if (contextPlayer) {
            loadDataAndUpdateState(contextPlayer.id).then((player: PlayerViewModel | undefined) => {
                if (player) {
                    initializeWebSocketMessageHandlers(player);
                    connectWebSocketAndJoinRoom(player);
                }
            });

            window.addEventListener("focus", () => {
                //alert.showMessage("window got focus with connection state '" + connection.current.state + "'", "info");
                if (connection.current.state === HubConnectionState.Disconnected) {
                    changeStateForPlayer(contextPlayer.id, PlayerState.ConnectionProblem);

                    loadDataAndUpdateState(contextPlayer.id).then((player: PlayerViewModel | undefined) => {
                        if (player) {
                            connectWebSocketAndJoinRoom(player);
                        }
                    });
                }
            });
        }
    }, [contextPlayer?.id]);

    useEffect(() => {
        if (authState == AuthState.SignedIn && contextPlayer?.isModerator) {
            const httpClient = new HttpClient(authToken);
            const request = new EnrollModeratorPowersRequest(props.roomUrlId, contextPlayer.id);
            httpClient.post("/rooms/enrollModeratorPowers", request);
        }
        //important that we a listening to both authstate and isModerator changes because the user can signin, signup and become modeator during game
    }, [authState, contextPlayer?.isModerator, user?.plan.status]);

    function connectWebSocketAndJoinRoom(player: PlayerViewModel): void {
        connection.current
            .start()
            .then(() => {
                //console.log("websocket connected with connection id: " + connection.current.connectionId?.toString());
                sendJoinRoomMessage(player);
            })
            .catch((err) => document.write(err));
    }

    function ifMessageIsFromAnotherConnectionThen(connectionId: string, action: () => void) {
        if (connection.current.connectionId?.toString() !== connectionId) {
            action();
        }
    }

    function sendJoinRoomMessage(player: PlayerViewModel): void {
        //console.log("websocket join room with username:'" + user.name + "'")
        connection.current.send("JoinRoom", player.id, player.name, props.roomUrlId).then(() => {
            //console.log("Message 'JoinRoom' sent")
        });
    }

    function initializeWebSocketMessageHandlers(player: PlayerViewModel) {
        connection.current.on("gameStateChanged", (message: GameStateChangedMessage) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeGameState(message.body.state as GameState, false);
            });
        });

        connection.current.on("playerJoinedRoom", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                const newPlayer = new PlayerViewModel(message.sender.playerId, "", message.sender.playerName, false, false, PlayerState.Active, undefined);
                const existingPlayer = playersRef.current.find((p) => p.id === newPlayer.id);
                if (existingPlayer) {
                    existingPlayer.state = PlayerState.Active;
                } else {
                    playersRef.current.push(newPlayer);
                }
                setPlayers([...playersRef.current]);
            });
        });

        connection.current.on("playerCardSelected", (message: CardSelectedMessage) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                handleCardSelectedByPlayer(message.sender.playerId, message.body.storyPoint as StoryPoint);
            });
        });

        connection.current.on("playerModerationStarted", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeModerationForPlayer(message.sender.playerId, true);
            });
        });

        connection.current.on("playerModerationStopped", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                changeModerationForPlayer(message.sender.playerId, false);
            });
        });

        connection.current.on("playerObservingStarted", (message: Message) => {
            if (player.id === message.sender.playerId) {
                alert.showMessage('You started observing and are not able to play any cards 😌', "info");
            } else {
                const observingPlayer = playersRef.current.find(p => p.id === message.sender.playerId);
                if (observingPlayer) {
                    observingPlayer.isObserver = true;
                    setPlayers([...playersRef.current]);
                }
            }
        });

        connection.current.on("playerObservingStopped", (message: Message) => {
            if (player.id === message.sender.playerId) {
                alert.showMessage('You stopped observing and are able to play cards again 😌', "info");
            } else {
                const observingPlayer = playersRef.current.find(p => p.id === message.sender.playerId);
                if (observingPlayer) {
                    observingPlayer.isObserver = false;
                    setPlayers([...playersRef.current]);
                }
            }
        });

        connection.current.on("playerDisconnected", (message: Message) => {
            ifMessageIsFromAnotherConnectionThen(message.sender.connectionId, () => {
                const player = playersRef.current.find((p) => p.id === message.sender.playerId);
                if (player) {
                    player.state = PlayerState.Inactive;
                    setPlayers([...playersRef.current]);
                }
            });
        });

        connection.current.on("playerKicked", (message: Message) => {
            if (player.id === message.receiver?.playerId) {
                alert.showMessage("You have been kicked from the game, sorry 😌", "warning");
                sessionStorage.DeleteSession(props.roomUrlId);
                history.replace("/" + props.roomUrlId);
            } else {
                const kickedPlayerIndex = playersRef.current.findIndex((p) => p.id === message.receiver?.playerId);
                if (kickedPlayerIndex >= 0) {
                    playersRef.current.splice(kickedPlayerIndex, 1);
                    setPlayers([...playersRef.current]);
                } else {
                    //console.error("cannot find player to kick");
                }
            }
        });

        connection.current.on("playerRenamed", (message: Message) => {
            const renamedPlayerIndex = playersRef.current.findIndex((p) => p.id === message.sender.playerId);
            if (renamedPlayerIndex >= 0) {
                const renamedPlayer = playersRef.current[renamedPlayerIndex];
                if (renamedPlayer) {
                    const previousName = renamedPlayer.name;
                    renamedPlayer.name = message.sender.playerName;

                    setPlayers([...playersRef.current]);

                    if (player.id === message.sender.playerId) {
                        alert.showMessage(`You have been renamed to '${message.sender.playerName}' 😌`, "info");
                    } else {
                        alert.showMessage(`'${previousName}' has been renamed to '${message.sender.playerName}' 😌`, "info");
                    }
                }
            } else {
                console.error("cannot find the renamed player");
            }
        });

        connection.current.on("playerLeftRoom", (message: Message) => {
            if (player.id == message.sender.playerId) {
                alert.showMessage("You left the room. See you again soon 🙏", "info");
                sessionStorage.DeleteSession(props.roomUrlId);
                history.replace("/" + props.roomUrlId);
            } else {
                alert.showMessage(`'${message.sender.playerName}' left the room.`, "info");
                const leftPlayerIndex = playersRef.current.findIndex((p) => p.id === message.sender.playerId);
                if (leftPlayerIndex >= 0) {
                    playersRef.current.splice(leftPlayerIndex, 1);
                    setPlayers([...playersRef.current]);
                } else {
                    console.error("cannot find the leaving player");
                }
            }
        });

        connection.current.on("playingCardsUpdated", (message: PlayingCardsUpdatedMessage) => {
            const orderedCards = message.playingCards.sort((c) => c.order);
            playingCardsRef.current = orderedCards.map((p) => ({ storyPoint: p.value as StoryPoint, selected: false }));
            gameStorage.SaveNumberOfPlayingCards(playingCardsRef.current.length);
            setCardDeckType(message.cardDeckType);
            resetGame();
        });

        connection.current.on("maxNumberOfPlayersReached", (message: Message) => {
            console.error("maxNumberOfPlayersReached");
            if (player.isModerator) {
                alert.showMessageWithAction(
                    "'" + message.sender.playerName + "' cannot enter the room because the player limit is reached. Please sign up to open up the room 👍",
                    "error",
                    "Sign Up",
                    () => {
                        setIsSignUpDialogOpen(true);
                    }
                );
            }
        });

        connection.current.on("maxNumberOfPlayersWarning", (message: Message) => {
            if (player.isModerator) {
                alert.showMessageWithAction(
                    "You are now at the limit of players for 'Basic' plan rooms. Please sign up if you need more players 👍",
                    "info",
                    "Sign Up",
                    () => {
                        setIsSignUpDialogOpen(true);
                    }
                );
            }
        });

        connection.current.onclose((error?: Error | undefined) => {
            //first called when reconnect fails
            console.error("websocket closed with error: " + error?.message);
            changeStateForPlayer(player.id, PlayerState.Inactive);
            alert.showMessage("Connection lost! Please try to refresh your browser 🤞", "error");
        });

        connection.current.onreconnecting((error?: Error | undefined) => {
            //called as the first thing when the connection is lost
            console.error("websocket reconnecting with error: " + error?.message);
            changeStateForPlayer(player.id, PlayerState.ConnectionProblem);
        });

        connection.current.onreconnected((connectionId?: string) => {
            //console.log("websocket reconnected with connection id: " + connectionId?.toString());
            //covers the scenario (among others) where a mobile has been locked and unlocks to participate again.
            //when IsConnected is set to true this triggers "JoinRoom" message which notifies other players that I'm back online
            //loadDataAndUpdateState() updates my own game state since there could have been messages that I haven't recieved while being offline

            loadDataAndUpdateState(player.id).then((player: PlayerViewModel | undefined) => {
                if (player) {
                    sendJoinRoomMessage(player);
                    changeStateForPlayer(player.id, PlayerState.Active);
                }
            });
        });
    }

    useEffect(() => {
        if (contextPlayer) {
            changeModerationForPlayer(contextPlayer.id, contextPlayer.isModerator);
            if (connection.current.state === HubConnectionState.Connected) {
                const message = contextPlayer.isModerator ? "StartModerating" : "StopModerating";
                connection.current.send(message, contextPlayer.id, contextPlayer.name, props.roomUrlId).then(() => {
                    //console.log("Message '" + message + "' sent")
                });
            }
        }
    }, [contextPlayer?.isModerator]);

    function changeGameState(state: GameState, notifyOtherPlayers: boolean) {
        if (state === GameState.Playing) {
            resetGame();
            _roundCount++;
        }
        setGameState(state);
        if (notifyOtherPlayers) {
            if (contextPlayer) {
                if (connection.current.state === HubConnectionState.Connected) {
                    connection.current.send("ChangeGameState", contextPlayer.id, contextPlayer.name, props.roomUrlId, state).then(() => {
                        //console.log("Message 'ChangeGameState' sent")
                    });
                }
            }
        }
    }

    function resetGame() {
        playersRef.current.forEach((p) => (p.storyPoint = undefined));
        setPlayers([...playersRef.current]);
        playingCardsRef.current.forEach((c) => (c.selected = false));
        setPlayingCards([...playingCardsRef.current]);
    }

    function handleOnGameStarted() {
        if (gameState === GameState.WaitingForPLayers) {
            track.event(Category.Game, Action.GameStarted, playersRef.current.length + " players");
        }

        changeGameState(GameState.Playing, true);

        track.event(Category.Game, Action.EstimationRoundStarted, "estimation round " + _roundCount);
        gameStorage.SaveLastEstimationRoundStartedAt(new Date());
    }

    function handleOnGameStopped() {
        changeGameState(GameState.Result, true);

        track.event(Category.Game, Action.EstimationRoundCompleted, "estimation round " + _roundCount);

        const lastRoundStartedAt = gameStorage.GetLastEstimationRoundStartedAt();
        if (lastRoundStartedAt !== null) {
            const timestamp = new Date();
            const roundLengthInMS = timestamp.getTime() - lastRoundStartedAt.getTime();
            track.timing(Category.Game, Timing.EstimationRoundLength, roundLengthInMS, "estimation round " + _roundCount);
        }
    }

    function handleCardSelected(storyPoint?: StoryPoint) {
        if (contextPlayer) {
            handleCardSelectedByPlayer(contextPlayer.id, storyPoint);
            if (connection.current.state === HubConnectionState.Connected) {
                connection.current.send("SelectCard", contextPlayer.id, contextPlayer.name, props.roomUrlId, storyPoint).then(() => {
                    //console.log("Message 'SelectCard' sent")
                });
            }
		}
    }

    function handlePlayerKicked(player: PlayerViewModel) {
        if (contextPlayer) {
            if (connection.current.state === HubConnectionState.Connected) {
                connection.current.send("KickPlayer", contextPlayer.id, contextPlayer.name, props.roomUrlId, player.id, player.name).then(() => {
                    //console.log("Message 'KickPlayer' sent");
                });
            }
        }
    }

    function handlePlayerToggledObserving(player: PlayerViewModel) {
        if (contextPlayer) {
            if (connection.current.state === HubConnectionState.Connected) {
                if (player.isObserver) {
                    connection.current.send("StartObserving", player.id, player.name, props.roomUrlId);
				} else {
                    connection.current.send("StopObserving", player.id, player.name, props.roomUrlId);
				}
			}
		}
    }

    function handlePlayerLeftRoom() {
        if (contextPlayer) {
            if (connection.current.state === HubConnectionState.Connected) {
                connection.current.send("LeaveRoom", contextPlayer.id, contextPlayer.name, props.roomUrlId).then(() => {
                    //console.log("Message 'LeaveRoom' sent");
                });
            }
        }
    }

    function handleCardSelectedByPlayer(playerId: string, storyPoint?: StoryPoint) {
        const p = playersRef.current.find((p) => p.id === playerId);
        if (p) {
            p.storyPoint = storyPoint;
            setPlayers([...playersRef.current]);
            // update the playingcard in case the player has multiple sessions open at the same time
            if (p.id === contextPlayer?.id) {
                const selectedCard = playingCardsRef.current.find((c) => c.storyPoint === storyPoint);
                if (selectedCard) {
                    playingCardsRef.current.forEach((c) => (c.selected = false));
                    selectedCard.selected = true;
                    setPlayingCards([...playingCardsRef.current]);
                }
            }
        }
    }

    function changeModerationForPlayer(playerId: string, isModerator: boolean) {
        const p = playersRef.current.find((p) => p.id === playerId);
        if (p) {
            p.isModerator = isModerator;
            setPlayers([...playersRef.current]);
        }
    }

    function changeStateForPlayer(playerId: string, playerState: PlayerState) {
        const p = playersRef.current.find((p) => p.id === playerId);
        if (p) {
            p.state = playerState;
            setPlayers([...playersRef.current]);
        }
    }

    //function renderContent(gameState: GameState, isModerator: boolean) {
    function renderContent(gameState: GameState, contextPlayer: ContextPlayer) {
        const currentPlayer = playersRef.current.find((p) => p.id == contextPlayer.id);

        switch (gameState) {
            case GameState.WaitingForPLayers:
                return <GameStateMessage state={gameState} isModerator={contextPlayer.isModerator} />;
            case GameState.Suspended:
                return <GameStateMessage state={gameState} isModerator={contextPlayer.isModerator} />;
            case GameState.Playing:
                return currentPlayer && currentPlayer.isObserver ? <></> : <CardDeck playingCards={playingCards} onCardSelected={handleCardSelected} />;
            case GameState.Result:
                return (
                    <EstimationResult
                        playerEstimates={players.map<PlayerEstimate>((p) => ({ playerName: p.name, storyPoint: p.storyPoint }))}
                        roundCount={_roundCount}
                    />
                );
            case GameState.Loading:
                return (
                    <Grid container className={classes.loaderContainer} spacing={2} justifyContent="center" alignItems="center" direction="column">
                        <Grid item xs={12}>
                            <CircularProgress color="secondary" />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.outerContainer} spacing={0} direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={4} md={3}>
                    <PlayerList
                        gameState={gameState}
                        players={players}
                        onPlayerKicked={(player) => handlePlayerKicked(player)}
                        onPlayerToggledObserving={(player) => handlePlayerToggledObserving(player)}
                        onPlayerLeftRoom={() => handlePlayerLeftRoom()}
                        roomUrlId={props.roomUrlId}
                    />
                    {contextPlayer?.isModerator && <SignUpTeaser roomUrlId={props.roomUrlId} gameState={gameState} />}
                    {contextPlayer?.isModerator && authState == AuthState.SignedIn && (
                        <GameInsights roomUrlId={props.roomUrlId} gameState={gameState} cardDeckType={cardDeckType} />
                    )}
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    {contextPlayer && renderContent(gameState, contextPlayer)}
                    {contextPlayer && gameState !== GameState.Loading && gameState !== GameState.Suspended && contextPlayer.isModerator ? (
                        <ModerationPanel
                            gameState={gameState}
                            isRevealEnabled={players.some((p) => p.storyPoint != undefined)}
                            onStarted={handleOnGameStarted}
                            onStopped={handleOnGameStopped}
                        />
                    ) : (
                        <div />
                    )}
                </Grid>
            </Grid>
            {isSignUpDialogOpen && <SignUpDialog open={isSignUpDialogOpen} onClosed={() => setIsSignUpDialogOpen(false)} />}
        </div>
    );
}
