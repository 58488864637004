export class Session {
    roomUrlId: string;
    /** should be renamed to playerId but is kept for backwards compatibility for now */
    userId: string;
    /** should be renamed to playerName but is kept for backwards compatibility for now */
    userName: string;

    isMessageSignUpTeaserClosed: boolean;

    constructor(roomUrlID: string, userId: string, userName: string) {
        this.roomUrlId = roomUrlID;
        this.userId = userId;
        this.userName = userName;
        this.isMessageSignUpTeaserClosed = false;
    }
}

export class SessionStorage {
    public SaveSession(session: Session): Session {
        window.localStorage.setItem(session.roomUrlId, JSON.stringify(session));
        //console.log('session saved for room with id: ' + roomUrlId);
        return session;
    }

    public GetSession(roomUrlId: string): Session | null {
        // Get from local storage by key
        const item = window.localStorage.getItem(roomUrlId);
        //console.log('session fetched for room with id: ' + roomUrlId);
        return item ? (JSON.parse(item) as Session) : null;
    }

    public DeleteSession(roomUrlId: string): void {
        window.localStorage.removeItem(roomUrlId);
    }

    public UpdatesMessageSignUpTeaserClosed(roomUrlId: string, isMessageClosed: boolean) {
        const session = this.GetSession(roomUrlId);
        if (session) {
            session.isMessageSignUpTeaserClosed = isMessageClosed;
            this.SaveSession(session);
        }
    }

    public UpdatePlayerName(roomUrlId: string, newPlayerName: string) {
        const session = this.GetSession(roomUrlId);
        if (session) {
            session.userName = newPlayerName;
            this.SaveSession(session);
        }
    }
}
