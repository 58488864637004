import React, { ReactElement, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Button, Link, Theme, Typography } from "@mui/material";
import { SignUpDialog } from "./SignUpDialog";
import { WindowGridContainer } from "../components/WindowGridContainer";
import { Action, Category } from "../analytics/Tracking";
import { AuthState, UserContext } from "../contexts/UserContext";
import { useTracking } from "../analytics/useTracking";
import { GameState } from "../features/Room";
import { SessionStorage } from "../localstorage/SessionStorage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        contentItem: {
            margin: theme.spacing(1),
        },
    })
);

export interface SignUpTeaserProps {
    roomUrlId: string;
    gameState: GameState;
}

export default function SignUpTeaser(props: SignUpTeaserProps): ReactElement {
    const { track } = useTracking();
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCongratsMessageVisible, setIsCongratsMessageVisible] = useState(false);
    const [isSignUpTeaserMessageVisible, setIsSignUpTeaserMessageVisible] = useState(true);
    const sessionStorage = new SessionStorage();
    const { authState } = useContext(UserContext);

    useEffect(() => {
        //initialize whether the teaser message should be visible after hard refresh
        const session = sessionStorage.GetSession(props.roomUrlId);
        if (session) {
            if (session.isMessageSignUpTeaserClosed || authState == AuthState.Authenticating || authState == AuthState.Initializing || authState == AuthState.SignedIn) {
                setIsSignUpTeaserMessageVisible(false);
            } else {
              setIsSignUpTeaserMessageVisible(true);
            }
        }
    }, [authState]);

    function handleSignUpClicked() {
        track.event(Category.User, Action.SignUpOpened, "signup teaser");
        setIsDialogOpen(true);
    }

    function handleSignUpCompleted() {
        setIsCongratsMessageVisible(true);
        handleCloseSignUpTeaser();
    }

    function handleCloseCongrats() {
        setIsCongratsMessageVisible(false);
   }

    function handleCloseSignUpTeaser() {
        setIsSignUpTeaserMessageVisible(false);
        sessionStorage.UpdatesMessageSignUpTeaserClosed(props.roomUrlId, true);
    }

    return isCongratsMessageVisible ? (
        <WindowGridContainer title="Congrats 🎉">
            <Grid item className={classes.contentItem}>
                <Typography color="inherit" variant="body2" align="center">
                    Your moderator powers has increased 🥳
                    <li>moderator insights 🧐</li>
                    <li>unlimited players 🚀</li>
                    <li>more card decks to choose from 🃏</li>
                    <li>reuse the room link in recurring calendar invites 🗓️</li>
                    <li>kick other players 🦿</li>
                </Typography>
            </Grid>
            <Grid item>
                <Button className={classes.button} onClick={handleCloseCongrats} variant="contained" size="small" color="primary">
                    Got It
                </Button>
            </Grid>
        </WindowGridContainer>
    ) : isSignUpTeaserMessageVisible ? (
        <WindowGridContainer title="Sign Up" onClosed={handleCloseSignUpTeaser}>
            <Grid item className={classes.contentItem}>
                <Typography color="inherit" variant="body2" align="center">
                    Gain moderator powers by signing up for a <i>30 days free trial</i> of the{" "}
                    <Link underline="always" target="_blank" href="https://storypoint.poker/#prices" color="inherit">
                        Business Subscription
                    </Link>
                    . It only takes a few seconds and can be done while{" "}
                    {props.gameState == GameState.WaitingForPLayers ? "waiting for your team mates to join 🚀" : "the game proceeds 🚀"}
                </Typography>
            </Grid>
            <Grid item>
                <Button className={classes.button} onClick={handleSignUpClicked} variant="contained" size="small" color="secondary">
                    Sign Up Now
                </Button>
            </Grid>
            {isDialogOpen && <SignUpDialog open={isDialogOpen} onClosed={() => setIsDialogOpen(false)} onCompleted={handleSignUpCompleted} />}
        </WindowGridContainer>
    ) : (
        <></>
    );
}