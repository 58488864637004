/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Tracking } from './Tracking';


export interface TrackingContextProps {
  track: Tracking;
 }

export const TrackingContext = React.createContext<TrackingContextProps>({
    track: new Tracking(),
});