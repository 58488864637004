import React, { ReactElement, useEffect, useState } from "react";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { ContextPlayer, PlayerContext } from "../contexts/PlayerContext";
import { lightTheme } from "../themes/lightTheme";
import { darkTheme } from "../themes/darkTheme";
import { AlertProvider } from "../contexts/alert/AlertProvider";
import { Tracking } from "../analytics/Tracking";
import { CssBaseline, StyledEngineProvider, useMediaQuery } from "@mui/material";
import Elmahio from "elmah.io.javascript";
import { AuthProvider } from "../auth/AuthProvider";
import AppContent from "./AppContent";
import { FirebaseProvider } from "../analytics/FirebaseProvider";
import { FirebaseApp } from "firebase/app";
import { Analytics } from "firebase/analytics";
import { TrackingProvider } from "../analytics/TrackingProvider";

export default function App(): ReactElement {
    const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>();
    const [firebaseAnalytics, setFirebaseAnalytics] = useState<Analytics>();
    const [player, setPlayer] = useState<ContextPlayer>();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    function updateContextPlayer(name: string, isModerator: boolean) {
        if (player) {
            setPlayer(new ContextPlayer(player.id, name, isModerator));
        } else {
            console.warn("could not update the player context because it is undefined")
        }
    }

    useEffect(() => {
        const apiKey = process.env.REACT_APP_ELMAHIO_API_KEY;
        const logId = process.env.REACT_APP_ELMAHIO_LOG_ID;
        if (apiKey && logId) {
            new Elmahio({ apiKey: apiKey, logId: logId, breadcrumbs: true });
        }
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={responsiveFontSizes(prefersDarkMode ? darkTheme : lightTheme)}>
                <CssBaseline />
                <FirebaseProvider
                    onAppInitialized={(app) => {
                        setFirebaseApp(app);
                    }}
                    onAnalyticsInitialized={(analytics) => setFirebaseAnalytics(analytics)}
                >
                    <TrackingProvider firebaseAnalytics={firebaseAnalytics}>
                        <PlayerContext.Provider value={{ player: player, setPlayer: setPlayer, updatePlayer: updateContextPlayer }}>
                            <AuthProvider firebaseApp={firebaseApp}>
                                <AlertProvider>
                                    <Router>
                                        <AppContent />
                                    </Router>
                                </AlertProvider>
                            </AuthProvider>
                        </PlayerContext.Provider>
                    </TrackingProvider>
                </FirebaseProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
