import React, { useState, useContext, SyntheticEvent, ReactElement } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, Theme, Typography } from "@mui/material";
import { ConflictError, HttpClient, NotFoundError, UnauthorizedError } from "../api/HttpClient";
import { useAlert } from "../contexts/alert/useAlert";
import { LoadingButton } from "../components/LoadingButton";
import { Category, Action } from "../analytics/Tracking";
import { InputValidatorMode, useUserNameValidator } from "../contexts/useInputValidator";
import { RoomResponse } from "../api/Requests";
import { SignUpDialog } from "../auth/SignUpDialog";
import { UserContext } from "../contexts/UserContext";
import { useJoinRoom } from "../hooks/useRoom";
import { useTracking } from "../analytics/useTracking";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(1),
    },
  })
);

export interface JoinRoomProps {
  roomUrlId: string;
}

export default function JoinRoom(props: JoinRoomProps): ReactElement {
  const [validateUserName] = useUserNameValidator();
  const [playerName, setPlayerName] = useState("");
  const classes = useStyles();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const { track } = useTracking();
  const { authToken } = useContext(UserContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);
  const [joinRoom] = useJoinRoom();

  function validateInput(inputUserName: string, mode: InputValidatorMode) {
    const isValid = validateUserName(inputUserName, mode).isValid;
    setIsValid(isValid);
    return isValid;
  }

  function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setPlayerName(event.target.value);
    validateInput(event.target.value, InputValidatorMode.onTyping);
  }

  function handleSignUpClicked() {
    track.event(Category.User, Action.SignUpOpened,"join room (regain access)");
    setIsDialogOpen(true);
  }

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (!validateInput(playerName, InputValidatorMode.onSubmit)) {
      return;
    }

    setIsLoading(true);
    const httpClient = new HttpClient(authToken);
    try {
      const response = (await httpClient.post("/rooms/join", { roomUrlId: props.roomUrlId, playerName: playerName })) as RoomResponse;

      const player = response.players.find((p) => p.name.toLowerCase() === playerName.toLocaleLowerCase());
      if (player) {
        joinRoom(response.urlId, player.id, player.name, player.isModerator)
      } else {
        setIsLoading(false);
        alert.showMessage("an unexpected error occured, please try again 🤞", "error");
      }
    } catch (e) {
      let errorMessage = "Not able to join room, please try again 🤞";
      if (e instanceof UnauthorizedError) {
        setIsSignUpVisible(true);
        errorMessage = e.message;
      } else if (e instanceof ConflictError) {
        errorMessage = "Player name already in use, please try another 👍";
      } else if (e instanceof NotFoundError) {
        errorMessage = "Room with id '" + props.roomUrlId + "' does not exist 😞";
      }
      setIsLoading(false);
      alert.showMessage(errorMessage, "error");
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography align="center" variant="h5">
              Join the planning poker room
            </Typography>
            <Typography align="center" variant="subtitle1">
              and participate in the estimation session with your team
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              error={!isValid}
              helperText={validateUserName(playerName, InputValidatorMode.onTyping).errorMessage}
              disabled={isLoading}
              onChange={handleOnChange}
              id="outlined-basic"
              label="Please enter your name"
              variant="outlined"
              color="secondary"
              autoComplete="nickname"
            />
          </Grid>
          <Grid item>
            <LoadingButton type="submit" isLoading={isLoading}>Join Room</LoadingButton>
          </Grid>
          {isSignUpVisible && (
            <Grid item>
              <Button className={classes.button} onClick={handleSignUpClicked} size="large" variant="outlined" color="secondary">
                Sign Up and gain access to the room
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      {isDialogOpen && <SignUpDialog open={isDialogOpen} onClosed={() => setIsDialogOpen(false)} />}
    </div>
  );
}
