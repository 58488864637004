import React, { ReactElement, SyntheticEvent, useContext, useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, Theme, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { RoomParams } from "../types/RouterTypes";
import { HttpClient } from "../api/HttpClient";
import { useAlert } from "../contexts/alert/useAlert";
import { PlayingCardDTO } from "../api/DTO";
import { AppSettingsStorage } from "../localstorage/AppSettingsStorage";
import { Category, Action } from "../analytics/Tracking";
import { CardDeckType, RoomResponse, UpdatePlayingCardsRequest } from "../api/Requests";
import { UserContext } from "../contexts/UserContext";
import { PlanStatus, PlanType } from "../api/models/User";
import { LoadingButton } from "../components/LoadingButton";
import { createStyles, makeStyles } from "@mui/styles";
import { useTracking } from "../analytics/useTracking";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3),
        },
        formControl: {
            margin: theme.spacing(2),
        },
        formControlLabel: {
            width: 100,
        },
        checkBoxContainer: {
            columnCount: 3,
            maxHeight: 220,
        },
    })
);

class PlayingCardViewModel {
    value: string;
    order: number;
    isEnabled: boolean;

    constructor(value: string, order: number, isEnabled: boolean) {
        this.value = value;
        this.order = order;
        this.isEnabled = isEnabled;
    }
}

interface CardDeckSettingsProps {
    onSaved?: () => void;
}

export function CardDeckSettings(props: CardDeckSettingsProps): ReactElement {
    const [cardDeckType, setCardDeckType] = React.useState(CardDeckType.Unknown);
    const [cards, setCards] = React.useState<PlayingCardViewModel[]>([]);
    const { id: roomUrlId } = useParams<RoomParams>();
    const alert = useAlert();
    const classes = useStyles();
    const { track } = useTracking();
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);
    const hasActiveSubscription = (user?.plan.type == PlanType.Premium || user?.plan.type == PlanType.Trial) && user?.plan.status == PlanStatus.Active;
    const lockedPostFix = !hasActiveSubscription ? " (🔒 business plan)" : "";

    //TODO pri3: could be initialized from the backend to keep it even DRY'er
    const scrumScale = ["0", "1/2", "1", "2", "3", "5", "8", "13", "20", "40", "100", "?", "☕"];
    const fibonacciScale = ["0", "1", "2", "3", "5", "8", "13", "21", "34", "55", "89", "?", "☕"];
    const powersOfTwoScale = ["0", "1", "2", "4", "8", "16", "32", "64", "?", "☕"];
    const tShirtScale = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "?", "☕"];

    const onCardDeckTypeChanged = (event: SelectChangeEvent<CardDeckType>) => {
        const newType = event.target.value as CardDeckType;
        setCardDeckType(newType);
        updateCards(newType);
    };

    function updateCards(
        type: CardDeckType,
        cards?: [
            {
                order: number;
                value: string;
            }
        ]
    ) {
        const cardViewModels: PlayingCardViewModel[] = [];
        let scale: string[] = [];

        if (type == CardDeckType.Scrum) {
            scale = scrumScale;
        } else if (type == CardDeckType.Fibonacci) {
            scale = fibonacciScale;
        } else if (type == CardDeckType.PowersOfTwo) {
            scale = powersOfTwoScale;
        } else if (type == CardDeckType.TShirt) {
            scale = tShirtScale;
        }

        for (let i = 0; i < scale.length; i++) {
            cardViewModels.push(new PlayingCardViewModel(scale[i], i, cards == undefined ? true : cards?.find((c) => c.value === scale[i]) ? true : false));
        }

        setCards(cardViewModels);
        track.event(Category.Moderation, Action.CardDeckTypeChanged, type.toString());
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const httpClient = new HttpClient();
                const response = (await httpClient.get("/rooms/" + roomUrlId)) as RoomResponse;
                const cards = response.playingCards;

                setCardDeckType(response.cardDeckType);
                updateCards(response.cardDeckType, cards);

                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                alert.showMessage("Not able to initialize card deck, please try again 🤞", "error");
            }
        };
        fetchData();
    }, []);

    async function handleSave(event: SyntheticEvent) {
        event.preventDefault();

        setIsLoading(true);
        const httpClient = new HttpClient();
        try {
            const request = new UpdatePlayingCardsRequest(roomUrlId);
            request.cardDeckType = cardDeckType;
            request.playingCards = cards.filter((cards) => cards.isEnabled).map((card) => new PlayingCardDTO(card.order, card.value));

            await httpClient.post("/rooms/updatePlayingCards", request);
            const appSettings = new AppSettingsStorage();
            appSettings.SaveDefaultCardDeckType(request.cardDeckType);
            appSettings.SaveDefaultPlayingCards(request.playingCards);

            const cardDeck = request.playingCards.map<string>((card) => card.value);
            track.event(Category.Moderation, Action.PlayingCardsUpdated, cardDeck.toString());

            setIsLoading(false);
            props.onSaved && props.onSaved();
        } catch (e) {
            setIsLoading(false);
            alert.showMessage("Not able to update card deck, please try again 🤞", "error");
        }
    }

    //TODO pri3: Select label not shown when applied label="Card Deck Type"
    return (
        <Grid container className={classes.container} spacing={3} direction="column" justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography align="center" variant="h5">
                    Configure Card Deck
                </Typography>
            </Grid>
            <Grid item>
                <Select value={cardDeckType} onChange={onCardDeckTypeChanged}>
                    <MenuItem value={CardDeckType.Scrum}>Scrum scale</MenuItem>
                    <MenuItem value={CardDeckType.Fibonacci} disabled={!hasActiveSubscription}>
                        Fibonacci sequence{lockedPostFix}
                    </MenuItem>
                    <MenuItem value={CardDeckType.PowersOfTwo} disabled={!hasActiveSubscription}>
                        Powers of two{lockedPostFix}
                    </MenuItem>
                    <MenuItem value={CardDeckType.TShirt} disabled={!hasActiveSubscription}>
                        T-Shirt sizes{lockedPostFix}
                    </MenuItem>
                </Select>
            </Grid>

            <Grid item>
                <FormControl component="fieldset" className={classes.formControl}>
                    <div className={classes.checkBoxContainer}>
                        <FormGroup>
                            {cards.map((card) => [
                                <FormControlLabel
                                    key={card.order}
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            checked={card.isEnabled}
                                            onChange={(event) => {
                                                card.isEnabled = event.target.checked;
                                                setCards([...cards]);
                                            }}
                                            name={card.value}
                                        />
                                    }
                                    label={
                                        <Typography color="textPrimary" variant="body1">
                                            {card.value}
                                        </Typography>
                                    }
                                />,
                            ])}
                        </FormGroup>
                    </div>
                </FormControl>
            </Grid>
            <Grid item>
                <LoadingButton isLoading={isLoading} onClick={handleSave}>
                    Save Card Deck
                </LoadingButton>
            </Grid>
        </Grid>
    );
}
