import ReactGA from "react-ga";
import { Analytics, logEvent } from "firebase/analytics";

export enum Category {
    Game = "Game",
    Room = "Room",
    Moderation = "Moderation",
    User = "User",
}

export enum Action {
    //Room
    GameStarted = "Game Started",
    EstimationRoundStarted = "Estimation Round Started",
    EstimationRoundCompleted = "Estimation Round Completed",
    RoomCreated = "Room Created",
    RoomJoined = "Room Joined",
    PlayingCardsUpdated = "Playing Cards Updated",
    CardDeckTypeChanged = "Card Deck Type Changed",
    ModerationStarted = "Moderation Started",
    ModerationStopped = "Moderation Stopped",
    ModerationPlayerKicked = "Player Kicked",
    PlayerRenamed = "Player Renamed",

    //User
    SignUpOpened = "SignUp Opened",
    SignUpStarted = "SignUp Started",
    SignUpCompleted = "SignUp Completed",
    SignInStarted = "SignIn Started",
    SignInCompleted = "SignIn Completed",
    ResetPasswordRequestSent = "Reset Password Request Sent",
    SignOutCompleted = "SignOut Completed",
    RecentRoomVisited = "Recent Room Visited",
    PurchaseStarted = "Purchase Started",
    PurchaseCanceled = "Purchase Canceled",
    PurchaseCompleted = "Purchase Completed",
    PurchaseLinkCreated = "Purchase Link Created",
    TrialEnded = "Trial Ended",

    //Player
    PlayerLeftRoom = "Player Left Room",
    PlayerStartedObserving = "Player Started Observing Game",
    PlayerStoppedObserving = "Player Stopped Observing Game",
}

export enum Timing {
    EstimationRoundLength = "Estimation Round Length",
}

export class Tracking {
    analytics?: Analytics;

    public initialize(firebaseAnalytics: Analytics, trackingId: string): void {
        ReactGA.initialize(trackingId);
        this.analytics = firebaseAnalytics;
    }

    public event(category: Category, action: Action, label?: string): void {
        if (this.analytics) {
            logEvent(this.analytics, action.toString(), { event_category: category.toString(), event_label: label });
        } else {
            console.warn("trying to track analytics event before the firebase analytics is initialized!");
        }
    }

    public timing(category: Category, timing: Timing, timingMS: number, label?: string): void {
        if (this.analytics) {
            logEvent(this.analytics, timing.toString(), { timing_category: category.toString(), timing_ms:  timingMS, timing_label: label });
        } else {
            console.warn("trying to track analytics event before the firebase analytics is initialized!");
        }
    }
}
