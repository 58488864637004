import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

//TODO pri1: reuse declares across themes
declare module "@mui/material/styles/createTheme" {
    interface Theme {
        chart: {
            pieColors: React.CSSProperties["color"][];
        };
    }
    interface ThemeOptions {
        chart: {
            pieColors: React.CSSProperties["color"][];
        };
    }
}

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        icon: Palette["primary"];
        link: Palette["primary"];
    }
    interface PaletteOptions {
        icon: PaletteOptions["primary"];
        link: PaletteOptions["primary"];
    }
}

export const lightTheme = createTheme({
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 28,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minWidth: 250,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: 25,
                    minWidth: 220,
                    minHeight: 50,
                    [theme.breakpoints.between('md','lg')]: {
                        minWidth: 180,
                    },
                    [theme.breakpoints.only('sm')]: {
                        minWidth: 150,
                    },
                  }),
            },
        },
    },
    chart: {
        pieColors: ["#455a64", "#e64a19", "#fbc02d", "#388e3c", "#0288d1", "#512da8", "#d32f2f"],
    },
    typography: {
        h5: {
            fontSize: 24,
            fontDisplay: "swap",
            fontWeight: 600,
            color: "#000",
        },
        h6: {
            fontSize: 18,
            fontDisplay: "swap",
            fontWeight: 600,
        },
        subtitle1: {
            marginTop: 5,
            fontFamily: "Source+Sans+Pro",
            fontWeight: 400,
            color: grey[700],
        },
        body1: {
            fontFamily: "Source+Sans+Pro",
            color: "#000",
        },
        body2: {
            fontFamily: "Source+Sans+Pro",
            color: grey[700],
        },
    },
    palette: {
      mode: 'light',
        common: {
            black: "#000",
            white: "#fff",
        },
        background: {
            paper: "rgba(240, 240, 240, 1)",
            default: "#fafafa",
        },
        primary: {
            light: "#718792",
            main: "#455a64",
            dark: "#1c313a",
            contrastText: "#fff",
        },
        secondary: {
            light: "#718792",
            main: "#455a64",
            dark: "#1c313a",
            contrastText: "#fff",
        },
        success: {
            light: "#e1ffb1",
            main: "#7da453",
            dark: "#7da453",
            contrastText: "#fff",
        },
        warning: {
            light: "#ffad42",
            main: "#bb4d00",
            dark: "#bb4d00",
            contrastText: "#fff",
        },
        error: {
            light: "#ffa270",
            main: "#c63f17",
            dark: "#c63f17",
            contrastText: "#fff",
        },
        text: {
            primary: "#000",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
       },
        icon: {
            main: "#455a64",
        },
        link: {
            main: "#455a64",
        },
    },
});
