import React, { useEffect, useState } from "react";
import { ReactElement } from "react";
import { Analytics } from "firebase/analytics";
import { Tracking } from "./Tracking";
import { TrackingContext } from "./TrackingContext";

interface TrackingProviderProps {
    firebaseAnalytics?: Analytics;
}

export function TrackingProvider(props: React.PropsWithChildren<TrackingProviderProps>): ReactElement {
    const [tracking, setTracking] = useState<Tracking>(new Tracking());

    useEffect(() => {
        const initialize = async () => {
            console.log("initializing tracking provider");

            const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_UNIVERSAL_TRACKING_ID;
            if (trackingId) {
                const trackingInstance = new Tracking();
                if (props.firebaseAnalytics) {
                    trackingInstance.initialize(props.firebaseAnalytics, trackingId);
                }
                setTracking(trackingInstance);
                //console.log("tracking provider initialized");
            } else {
                console.warn("missing tracking id for google analytics universal tracking. tracking provider cannot be initialized!");
            }
        };
        if (props.firebaseAnalytics) {
            initialize();
        }
    }, [props.firebaseAnalytics]);

    return <TrackingContext.Provider value={{ track:  tracking }}>{props.children}</TrackingContext.Provider>;
}
